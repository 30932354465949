/** Base Styles **/

body {
  font-family: 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 17px;
  color: #3C3C3C;
  font-weight: 300;
}

a {
  color: @primaryColor;

  &:hover, &:active, &:focus {
    color: @baseColor;
  }

}

p {
  margin: 0 0 35px;
}

hr {
  border-top: 1px solid #DADADB;
}

/** Headlines **/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500;
}

h1, .h1 {
  font-family: 'Droid Serif', serif;
  font-size: 25px;
  line-height: 1.6;
  font-style: italic;
}

h2, .h2 {
  font-family: 'Droid Serif', serif;
  font-size: 22px;
  line-height: 1.5;
}

h3, .h3 {
  font-size: 19px;
  line-height: 1.4;
}

h4, .h4 {
  font-size: 17px;
  line-height: 1.3;
}



@media (max-width: 768px) {

  body {
    font-size: 16px;
  }

  h1, .h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  h2, .h2 {
    font-size: 19px;
    line-height: 1.5;
  }

  h3, .h3 {
    font-size: 18px;
    line-height: 1.4;
  }

  h4, .h4 {
    font-size: 17px;
    line-height: 1.3;
  }


}



/** Buttons **/

.btn {
  .border-radius(0);
  border: 0 none;
  text-transform: uppercase;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 8px 30px;
  font-size: 18px;
  line-height: 1.33;
  .border-radius(0);
}

.btn-primary {
  background-image: none;
  /*background-color: @primaryColor;*/
  .horizontal-gradient(@primaryColor 34%, @baseColor 65%);
  background-size: 300% 100%;
  .transition(all .6s);

  &:hover, &:focus {
    /*background-color: @baseColor;*/
    background-position:right bottom;
    //.skew(2deg, -2deg)
    //.skew(2deg, -2deg);
    -webkit-backface-visibility: hidden;
  }

}