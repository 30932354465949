/** Form Styles **/

div.csc-mailform {
  text-align: center;

  ol {
    list-style-type: none;

    li {
      margin-bottom: 25px;

      input, textarea {
        width: 100%;
        text-align: center;
        .transition(all .2s);
        padding: 5px;

        &:hover, &:focus {
          background: @primaryColor;
          border: 2px solid @primaryColor;

          color: #FFF;

        }

      }

      input[type="submit"] {
        background: @primaryColor;
        border: 0 none;
        color: #FFF;
        padding: 10px 0;
        font-weight: bold;
        &:hover, &:focus {
          background: darken(@primaryColor, 10%);
        }
      }

      textarea {
        resize: vertical;
        &:hover, &:focus {
          border: 1px solid @primaryColor;
        }
      }

      label {
        font-family: 'Droid Serif', serif;
        font-weight: normal;
        font-style: italic;
        margin-right: 0;
        margin-left: 0;
        width: inherit;
        float: none;
        display: block;
      }

    }

  }

}

#cookie-bar-prompt-logo {
  display: none !important;
}