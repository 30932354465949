/** Slideshow styles **/

@media (max-width: 768px) {
  #slideshow {
    iframe {
      min-height: 300px;
    }
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  #slideshow {
    iframe {
      min-height: 200px;
    }
  }
}

@media (min-width: 768px) {
  #slideshow {
    iframe {
      min-height: 375px;
    }
  }
}

@media (min-width: 992px) {
  #slideshow {
    iframe {
      min-height: 575px;
    }    
  }
}