/** Service styles **/

#servicesContainer {
  margin-bottom: 50px;

  hr {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .serviceElement {
    position: relative;
    color: #FFF;
    text-align: center;

    a {
      color: #FFF;
    }

    .overlay {
      .opacity(0);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(135, 136, 138, .7);
      padding: 0 20% !important;
      .transition(all .4s);

      .tableWrapper {
        display: table;
        height: 100%;
        width: 100%;
      }

      .innerContent {
        display: table-cell;
        vertical-align: middle;
      }

      h3 {
        color: #FFF;
        text-transform: uppercase;
        font-size: 25px;
      }

      hr {
        margin-bottom: 25px;
        margin-top: 25px;
      }

    }

    &:hover {
      .overlay {
        .opacity(1);
      }
    }

  }

}


@media (max-width: 768px) {
  #servicesContainer {

    .serviceElement {

      .overlay {
        .opacity(1);
      }

    }

  }
}

/*@media (min-width: 500px) and (max-width: 768px) {
  #servicesContainer {

    .serviceElement {

      .overlay {
        padding: 0 40px;
      }

    }

  }
}*/

@media (min-width: 768px) {
  #servicesContainer {

    .owl-carousel .owl-controls {
      display: none;
    }

  }
}