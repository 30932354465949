// Import Custom fonts
@import (less) url("https://fonts.googleapis.com/css?family=Droid+Serif:400,700");


/* ubuntu-300 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/ubuntu-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Light'), local('Ubuntu-Light'),
  url('../Fonts/ubuntu-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Fonts/ubuntu-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Fonts/ubuntu-v14-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../Fonts/ubuntu-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Fonts/ubuntu-v14-latin-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/ubuntu-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
  url('../Fonts/ubuntu-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Fonts/ubuntu-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Fonts/ubuntu-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../Fonts/ubuntu-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Fonts/ubuntu-v14-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-500 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('../Fonts/ubuntu-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
  url('../Fonts/ubuntu-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Fonts/ubuntu-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Fonts/ubuntu-v14-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../Fonts/ubuntu-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Fonts/ubuntu-v14-latin-500.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/ubuntu-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
  url('../Fonts/ubuntu-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Fonts/ubuntu-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Fonts/ubuntu-v14-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../Fonts/ubuntu-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Fonts/ubuntu-v14-latin-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
}



// Import Bootstrap
@import (less) "bootstrap.css";
@import (less) "bootstrap-theme.css";

// Import Plugins
@import (less) "responsiveSlides.css";
@import (less) "owl.carousel.css";

// Import less mixins and variables
@import (less) "less/variables.less";
@import (less) "less/mixins.less";

// Import custom styles
@import (less) "less/base.less";
@import (less) "less/header.less";
@import (less) "less/slideshow.less";
@import (less) "less/content.less";
@import (less) "less/services.less";
@import (less) "less/form.less";
@import (less) "less/footer.less";
