/** Footer styles **/

#footer {
  background: @footerBackgroundColor;
  padding: 25px 0;
  line-height: 23px;
  font-size: 15px;

  p {
    margin-bottom: 0;
  }

  h4 {
    font-weight: 500;
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-top: 0;
  }

  .pd-top {
    padding-top: 60px;
  }

  a {
    text-decoration: none;
    color: #3C3C3C;

    &:hover, &:active, &:focus {
      color: @primaryColor;
    }

  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: 24px;

    &.footerMenu {
      li {
        float: left;
        margin-left: 40px;

        &:first-child {
          margin-left: 0;
        }

        > a {
          text-transform: uppercase;
          font-size: 15px;
          color: #3C3C3C;
          font-weight: 500;

          &:hover, &:active, &:focus, &.active {
            color: @primaryColor;
          }

        }
      }
    }

  }

  .social {
    display: block;
    width: 25px;
    height: 25px;
    .transition(all .4s);

    &.facebook {
      background: url("../Images/ico_fb.png") no-repeat left center;
    }

    &:hover {
      background-position: right center;
      .rotate(360deg);
    }

  }

}


@media (max-width: 768px) {
  #footer{
    p {
      margin-bottom: 35px;
    }
    .pd-top {
      padding-top: 0;
    }
    ul.footerMenu {
      margin-bottom: 25px;
      float: left;
      line-height: 27px;
      li {
        margin-left: 20px;
      }
    }
    .social {
      float: right;
    }
    .gmap {
      padding-left: 0;
      padding-right: 0;
    }
  }
}