/** Content styles **/

#contentContainer {

  text-align: center;

  .container {
    position: relative;
  }

}


@media (min-width: 768px) {

    #contentContainer {

    margin-top: 80px;

    .contentElement {
      margin-bottom: 85px;

      &:last-child {
        margin-bottom: 0;
      }

    }

    .container {
      width: 740px;
      padding: 0 40px;
    }

  }


  #slideshow + #contentContainer {
    margin-top: 0;
    .contentElement {
      &:first-child {

        h1:first-child {
          padding: 40px 130px;
          background: #FFF;
          position: relative;
          top: -129px;
          z-index: 150;
          margin-bottom: -129px;
          margin-left: -40px;
          margin-right: -40px;
        }

      }
    }
  }
}