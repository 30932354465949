/** Header styles **/

#header {
  .container {
    position: relative;
  }
  .navbar.navbar-default {
    min-height: inherit;
    .border-radius(0);
    .box-shadow(none);
    border: 0 none;
    background: none;
    margin-bottom: 0;

    .navbar-nav {
      > .open > a, > .active > a {
        background: none;
        .box-shadow(none);
        color: @primaryColor;
      }
      > li {
        a {
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          font-weight: 300;
          color: @baseColor;
        }
        a:hover, a:focus {
          color: @primaryColor;
        }
      }
    }

    .dropdown-menu {
      .border-radius(0);
      text-align: center;
      > li {
        a:hover, a:focus {
          background: none;
        }
      }
    }

    .navbar-brand {
      padding: 15px 10px;
      height: auto;
      position: relative;
      object {
        pointer-events: none;
        width: 200px;
      }
      span {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0;
      }
    }

    .navbar-toggle {
      padding: 10px 8px;
      margin: 42px 15px 40px 0;
      border-color: @primaryColor;
      background: @primaryColor;

      .icon-bar {
        background-color: #FFF;
      }

    }

    .navbar-collapse {
      border-top: 0;
      .box-shadow(none);
      margin-left: -15px;
      margin-right: -15px;
    }

  }

  .btn-program {
    font-size: 14px;
    padding: 3px 15px 12px 15px !important;
    position: absolute;
    right: 15px;
    bottom: -42px;
    letter-spacing: 1px;
    z-index: 150;


    .glyphicon {
      font-size: 23px;
      top: 7px;
      margin-right: 4px;
      -webkit-transition-property: -webkit-transform;
      -webkit-transition-duration: 1s;

      -moz-transition-property: -moz-transform;
      -moz-transition-duration: 1s;
    }

    &:hover, &:focus, &:active{
      .skew(0, 0);

      .glyphicon {
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;

        -moz-animation-name: rotate;
        -moz-animation-duration: 2s;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
      }

    }

  }

}

@media (max-width: 768px) {
  #header {
    .navbar.navbar-default {
      margin-bottom: 0;
      .navbar-nav {
        margin: 0 -15px;
        > li {
          a {
            text-align: center;
            border-bottom: 1px solid darken(@baseColor, 5%);
          }
          &:first-child {
            a {
              border-top: 1px solid darken(@baseColor, 5%);
            }
          }
          &:last-child {
             a {
              border-bottom: 1px solid darken(@baseColor, 5%);
            }
          }

          a:hover, a:focus {
            color: #FFF;
            background: @baseColor;
          }

        }

        > .open > a, > .active > a {
          color: #FFF;
          background: @baseColor;
        }

      }
      .navbar-brand {
        padding: 15px 10px;
        object {
          width: 205px;
        }
      }

      .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
        background: #FFF;
        color: @primaryColor;
      }

      .dropdown-menu {
        background-color: @baseColor;

        > li > a {
          color: #FFF;
          border-bottom: 0;
        }
      }

    }

    .btn-program {
      font-size: 12px;
      right: 30px;
      bottom: -36px;

      .glyphicon {
        font-size: 18px;
        top: 5px;
        margin-right: 4px;
      }

    }

  }
}

@media (min-width: 768px) {
  #header {
    .navbar.navbar-default {
      .navbar-nav {
        > li {
          > a {
            padding: 8px 10px;
            border-right: 1px solid darken(@baseColor, 5%);
          }
          &:first-child {
            > a {
              border-left: 1px solid darken(@baseColor, 5%);
            }
          }

          &:last-child {
            > a {
              border-right: 0 none;
            }
          }
        }
      }
      .navbar-brand {
        object {
          width: 200px;
        }
      }

      .dropdown-menu {
        margin-top: 20px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
      }

      .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
        background: none;
        color: @primaryColor;
      }

    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #header {
    .navbar.navbar-default {
      .navbar-nav {
        &.navbar-right {
          float: left !important;
        }
        > li {
          a {
            padding: 8px 22px;
          }
          &:first-child {
            a {
              border-left: 0;
            }
          }
        }
      }
      .navbar-header {
        float: none;
        text-align: center;
      }
      .navbar-brand {
        display: block;
        float: none;
        object {
          width: 250px;
          display: inline;
        }
      }
      .dropdown-menu {
        margin-top: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  #header {
    .navbar.navbar-default {
      .navbar-nav {
        margin-top: 53px;
        > li {
          a {
            padding: 8px 15px;
          }
        }
      }
      .navbar-brand {
        object {
          width: 250px;
        }
      }
    }
    &.sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 200;
      background: #FFF;
      box-shadow: 0 6px 20px rgba(0,0,0,0.25);
    }
  }
}

@media (min-width: 1200px) {
  #header {
    .navbar.navbar-default {
      .navbar-nav {
        margin-top: 62px;
        > li {
          a {
            padding: 8px 30px;
          }
        }
      }
      .navbar-brand {
        object {
          width: 285px;
        }
      }
    }
  }
}



@-webkit-keyframes rotate {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}
}

@-moz-keyframes rotate {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(360deg);}
}