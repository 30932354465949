/*! http://responsiveslides.com v1.54 by @viljamis */

#slideshow .slides {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
}

#slideshow .slides li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
}

#slideshow .slides li:first-child {
    position: relative;
    display: block;
    float: left;
}

#slideshow .slides img {
    display: block;
    height: auto;
    float: left;
    width: 100%;
    border: 0;
}


@media (max-width: 768px) {

    #slideshow {
        margin-bottom: 20px;
    }

}